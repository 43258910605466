html {
  font-size: 62.5%;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: 'Poppins', 'NanumSquare', sans-serif;
  color: #222;
  font-size: 1.4rem;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  word-break: keep-all;
}

button {
  background-color: transparent;
  outline: none;
  border: none;
}

select {
  background-color: transparent;
  outline: none;
  border: none;
  appearance: none;
}

ul,
li {
  list-style: none;
}

textarea {
  outline: 0;
  resize: none;
}
input {
  outline: 0;
}
input::placeholder {
  color: #bdbdbd;
  font-family: 'NanumSquare';
}

img,
span {
  vertical-align: top;
}

div,
span,
p,
input,
button {
  font-weight: 400;
}

/* =================================================
  예약어
================================================== */
/* margin-top */
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-100 {
  margin-top: 100px;
}
/* margin-bottom */
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
/* margin-left */
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-100 {
  margin-left: 100px;
}
/* margin-right */
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-90 {
  margin-right: 90px;
}
.mr-100 {
  margin-right: 100px;
}

.inner-wrap {
  padding: 0 15px;
}

.swiper-vertical-pager .swiper-pagination-bullet {
  display: block;
}

.swiper-vertical-pager .swiper-pagination-bullet-active {
  background-color: #8a8a8a;
}
